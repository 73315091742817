(function($) {

  /**
   * Generic behaviors for every page load that don't need their own behavior
   */
  /**
   * Input Functionality
   *
   * Styleguide pg.177 and batch 2 styleguide p.73
   */
  Drupal.behaviors.formTextInputs = {
    attach: function(context, settings) {
      var $formTextInputs = $('.form-text, input.field[type="text"], input.field[type="email"], input.field[type="tel"], input.field[type="password"], textarea', context);

      $formTextInputs.on({
        'focus.formTextInputs': function() {
          $(this).addClass('filled-in').attr('placeholder', '');
        },
        'blur.formTextInputs': function() {
          if ($(this).val() === '') {
            $(this).removeClass('filled-in').attr('placeholder', $(this).data('placeholder'));
          } else {
            $(this).addClass('filled-in');
          }
        }
      });

      $formTextInputs.each(function() {
        $(this).data('placeholder', $(this).attr('placeholder'));
      }).filter(function(){
        return !!this.value.length;
      }).addClass('filled-in').attr('placeholder', '');

    },
    detach: function(context, settings) {
      var $formTextInputs = $('.form-text, input.field[type="text"], input.field[type="password"]', context);
      $formTextInputs.off('.formTextInputs').removeData('placeholder');
    }
  };
})(jQuery);
